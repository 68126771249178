<style lang="scss" scoped>
.page-instockbill-list {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
</style>

<template>
    <div class="page-instockbill-list">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button class="mrgr5 mrgb5" type="primary" size="medium" @click="putIn(inStockTypeArr[0])">采购入库</el-button>
                    <el-button class="mrgr5 mrgb5" type="primary" size="medium" @click="putIn()">常规入库</el-button>
                </div>
                <div class="fr">
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部属事业部" size="medium" filterable
                        clearable v-model="listQuery.companyId" @change="changeCompany" v-show="!isQuery">
                        <el-option v-for="ws in allCompany" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部仓库" size="medium" filterable
                        clearable v-model="listQuery.warehouseId" @change="_search" v-show="!isQuery">
                        <el-option v-for="ws in allWarehouse" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        align="right">
                    </el-date-picker>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="请输入关键字查询" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="medium" @cell-dblclick="_query">
                <el-table-column label="状态" prop="status">
                    <template slot-scope="scope">
                        <el-tag type="primary" size="small" v-if="scope.row.status === valBillSubmited()">{{ tranceStatus(scope.row.status) }}</el-tag>
                        <el-tag type="success" size="small" v-else-if="scope.row.status === valBillCompleted()">{{ tranceStatus(scope.row.status) }}</el-tag>
                        <el-tag type="warning" size="small" v-else-if="scope.row.status === valBillPending()">{{ tranceStatus(scope.row.status) }}</el-tag>
                        <el-tag type="info" size="small" v-else>{{ tranceStatus(scope.row.status) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="负责人" prop="principal"></el-table-column>
                <el-table-column label="来源" prop="assetUser.name">
                    <template slot-scope="scope">
                        {{ scope.row.assetUser && scope.row.assetUser.name || "采购入库" }}
                    </template>
                </el-table-column>
                <el-table-column label="仓库名称" prop="warehouse.name"></el-table-column>
                <el-table-column label="图片【分类-条形码】资产名称 数量 序列号" min-width="288">
                    <template slot-scope="scope">
                        <div v-for="item in scope.row.items" :key="item.assetId">
                            <span class="mrgr5" v-if="item.asset">
                                <el-image class="asset-image" v-if="item.asset.imageUrl"
                                    :src="uploadBaseUrl + item.asset.imageUrl" 
                                    :preview-src-list="[uploadBaseUrl + item.asset.imageUrl]">
                                    <div slot="error">
                                        <i class="el-icon-picture-outline error-image"></i>
                                    </div>
                                </el-image>
                                <span>{{ mxTranceAssetData(item.asset) }}</span>
                                <span :class="mxTranceAssetData(item.asset) ? '' : 'mrgl10'">{{ item.asset.name }}</span>
                            </span>
                            <span class="mrgl5 col_danger">x{{ item.count }}</span>
                            <el-button class="mrgl5" type="text" size="mini" title="查看序列号" @click="querySerialNumbers(item.serialNumbers)"
                                v-if="item.serialNumbers && item.serialNumbers.length > 0">
                                <i class="el-icon-view"></i>
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="入库单号" prop="id" min-width="100"></el-table-column>
                <el-table-column label="创建时间" prop="creationTime" :formatter="tableDateFormat" min-width="100"></el-table-column>
                <el-table-column label="操作" align="center" width="100" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="_query(scope.row)">查看详情</el-dropdown-item>
                                <el-dropdown-item @click.native="_cancel(scope.row)" v-if="scope.row.status === valBillPending()">撤销入库</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>

        <!--查看详情-->
        <detail :ref="refStockDetail"></detail>
        <!--入库-->
        <in-stock :ref="refInStock" @refreshData="_search"></in-stock>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
import { getStockCompany, getStockWarehourse } from "@/utils/storages"
import Detail from "./components/inStockDetail"
import InStock from "./components/stockIn"
import moment from "moment"
export default {
    name: "pageInStockBill",
    props: {
        params: {
            default: () => {
                return {}
            },
            type: Object
        }
    },
    mixins: [enumConfigs],
    components: { Detail, InStock },
    data() {
        let that = this
        return {
            refStockDetail: "refInStockBillToDetail",
            refInStock: "refInStockBillToInStock",
            tableData: [],
            total: null,
            listQuery: {
                companyId: "",
                warehouseId: "",
                beginDate: "",
                endDate: "",
                keywords: null,
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            allCompany: [],
            allWarehouse: [],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            isQuery: true,
            inStockTypeArr: ["BuyIn"]
        }
    },
    async mounted() {
        await this.getAllCompany()
        await this.getAllWarehouse()
        await this.open()
    },
    methods: {
        async open() {
            try {
                let companyItem = getStockCompany()
                let warehourseItem = getStockWarehourse()
                this.listQuery.companyId = companyItem && companyItem.id || ""
                this.listQuery.warehouseId = warehourseItem && warehourseItem.id || ""
                this.isQuery = true
            } catch(ex) {
                this.isQuery = false
                console.log("catch")
            }
            await this.getDataList()
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
        },
        async getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            await funStock.getInStockList(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async getAllCompany(){
            await funInfomation.searchCompany().then(res => {
                this.allCompany = res.items
            })
        },
        async getAllWarehouse() {
            await funInfomation.searchWarehouse({ companyId: this.listQuery.companyId }).then(res => {
                this.allWarehouse = res.items
            })
        },
        querySerialNumbers(items) {
            this.alert(items.join("，"), "序列号")
        },
        tranceStatus(status) {
            let back = ""
            try {
                let item = this.arrStockBillStatus.find(x => x.value === status)
                back = item && item.desc
            } catch(ex) {
                back = ""
            }
            return back
        },
        putIn(type) {
            if (type) {
                let params = { type }
                this.$refs[this.refInStock].open(params)
            } else {
                this.$refs[this.refInStock].open()
            }
        },
        _query(row) {
            this.$refs[this.refStockDetail].open(row)
        },
        _cancel(row) {
            this.confirm("确定要撤销入库吗？").then(()=>{
                window.$common.fullLoading()
                funStock.cancelInStock({ billId: row.id }).then(() => {
                    window.$common.closeFullLoading()
                    this.commonSuccess()
                })
            })
        },
        changeCompany() {
            this.listQuery.warehouseId = ""
            this.getAllWarehouse()
            this._search()
        },
        _search() { 
            this.listQuery.page = 1
            this.getDataList()
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.successMsg(tips)
            this.getDataList()
        }
    }
}
</script>